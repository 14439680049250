import React, { useState } from 'react';
import ApplicantCard from './ApplicantCard';

const ApplicantsList = ({ applicants }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const applicantsPerPage = 6;

    const indexOfLastApplicant = currentPage * applicantsPerPage;
    const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
    const currentApplicants = applicants.slice(indexOfFirstApplicant, indexOfLastApplicant);

    const totalPages = Math.ceil(applicants.length / applicantsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {currentApplicants.map(applicant => (
                    <ApplicantCard key={applicant._id} applicant={applicant} />
                ))}
            </div>
            <div className="flex justify-center mt-4">
                <ul className="inline-flex -space-x-px">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index}>
                            <button
                                onClick={() => paginate(index + 1)}
                                className={`px-3 py-2 border ${index + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'} rounded`}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ApplicantsList;
