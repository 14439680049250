import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../services/weevaluateagent";
import jobService from "../../services/jobService";
import InlineButtonControl from "../../components/InlineButtonControl";
import { useParams } from "react-router-dom";
import SkillsTagInput from "../../components/SkillsTagInput";
import OTabs from "../../../../components/OTabs/OTabs";
import JobDescriptionForm from "../../../InterviewGenius/components/Job/JobDescriptionForm";
import UploadJobWithDropzone from "../../../InterviewGenius/components/Job/UploadJobWithDropzone";

function JobForm({ jobId }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();
  const [isExisted, setIsExisted] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isMainDetailsEdit, setIsMainDetailsEdit] = useState(false);
  
  const qualifications = [
    "IX",
    "Bachelor's Degree",
    "Master's Degree",
    "Ph.D.",
    "Other",
  ]; // Define qualification options
  const rolesAndResponsibilities = [""];

  const getDefaultDate = () => {
    const defaultDate = new Date("2024-05-19"); // Default date set to April 30, 2024
    return defaultDate.toISOString().split("T")[0]; // Format date as 'YYYY-MM-DD'
  };
  const [formData, setFormData] = useState({
    _id: "",
    title: "",
    experience: "",
    noOfPositions: "",
    skills: [],
    location: "",
    description: "",
    qualifications: [],
    salary: 0,
    minSalary: "",
    maxSalary: "",
    rolesAndResponsibilities: [],
    benefits: [],
    applicationDeadline: getDefaultDate(),
    hiringManager: "",
    company: {
      name: "",
      website: "",
    },
    contactEmail: "",
    jobpdf: "", // file upload field
    equalOpportunityStatement: "",
    createdBy: "",
    updateDate: "",
  });

  const [skill, setSkill] = useState(formData?.skills || []);

  const handleSkillsChange = (newSkills) => {
    setFormData({
      ...formData,
      skills: newSkills,
    });
  };

  useEffect(() => {
    if (jobId) {
      console.log(" Job Id from props", jobId);
      setIsExisted(true);
      fetchJobData(jobId);
    }
    if (id) {
      console.log("Job Id", id);
      setIsExisted(true);
      fetchJobData(id);
    }
  }, [jobId, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await jobService.createJob(formData);
      setFormData(response);
      console.log("Job form data submitted successfully");
    } catch (error) {
      console.error("Error submitting Job form data:", error);
    }
  };

  const handleUpdate = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await jobService.updateJobById(id, formData);
      console.log("Job updated:", response);
    } catch (error) {
      console.error("Error updating Job:", error);
    }
  };

  const fetchJobData = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const response = await jobService.getJobById(id);
      setFormData(response);
      console.log("Job form data: ", response);
    } catch (error) {
      console.error("Error updating Job form data:", error);
    }
  };

  const mainDetailsEdit = () => {
    setIsMainDetailsEdit(true);
  };

  const mainDetailsSave = () => {
    setIsMainDetailsEdit(false);
    handleUpdate(formData?._id);
  };
  const handleAddRole = () => {
    setFormData((prevState) => ({
      ...prevState,
      rolesAndResponsibilities: [...prevState.rolesAndResponsibilities, ""],
    }));
  };
  const handleAddRequirments = () => {
    setFormData((prevState) => ({
      ...prevState,
      qualifications: [...prevState.qualifications, ""],
    }));
  };
  const handleListChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: prevFormData[name].map((item, i) => (i === index ? value : item)),
    }));
  };
 
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateWebsite = (website) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(website);
  };
  const tabs = [
    {
      name: "tab1",
      label: "Generate Job Description",
      content: (
        <JobDescriptionForm setSelectedJob={setFormData}  setIsExisted={setIsExisted}></JobDescriptionForm>
      ),
    },
    {
      name: "tab2",
      label: "Upload Job Document",
      content: <UploadJobWithDropzone setSelectedJob={setFormData}  setIsExisted={setIsExisted} />,
    },
  ];
  const handleJobHome = () => {
    navigate("/ats/jobInfo");
  };
  return (
    <div>
      
      
     
        
    
   

      <div className="max-w-4xl mx-auto bg-white rounded p-8 shadow-md mb-20">
        <div className="flex justify-end ">
        {!isExisted &&  <div className="flex items-center m-2">
                <input
                    type="checkbox"
                    id="manualCheckbox"
                    checked={isManual}
                    onChange={() => setIsManual(!isManual)}
                    className="mr-2"
                />
                <label htmlFor="manualCheckbox" className="text-gray-700"><strong>Manual Entry</strong></label>
            </div>}
      <div className=" m-5 top-4 right-4 space-x-2">
                <button className="bg-blue-500 text-white px-3 py-1 rounded-md" onClick={() => handleJobHome()}>Back to Job List</button>
            </div>
          
            </div>
      {!isManual && !jobId && !formData?._id && (
        <div className="max-w-4xl mx-auto bg-white rounded p-8 shadow-md">
          <OTabs tabs={tabs} />
        </div>
      )}
       {(isExisted || isManual) &&
      <div>
      <div style={{float:'right'}}>
      {isExisted && (
          <InlineButtonControl
            saveClick={mainDetailsSave}
            editClick={mainDetailsEdit}
          />
        )}
               
            </div>
   
          <div className="grid grid-cols-2 gap-4">
            <div className="mb-4">
              <div style={{ display: "none" }}>{formData?._id}</div>
              <label
                htmlFor="title"
                className="block text-gray-700 font-bold mb-2"
              >
                Title
              </label>

              {!isExisted || isMainDetailsEdit ? (
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Job Title"
                  required
                />
              ) : (
                <div className="data-value">{formData?.title}</div>
              )}
            </div>
            <div className="mb-4">
              <div style={{ display: "none" }}>{formData?._id}</div>
              <label
                htmlFor="noOfPositions"
                className="block text-gray-700 font-bold mb-2"
              >
                No Of Positions
              </label>

              {!isExisted || isMainDetailsEdit ? (
                <input
                  type="text"
                  id="noOfPositions"
                  name="noOfPositions"
                  value={formData.noOfPositions}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="No Of Positions"
                  required
                />
              ) : (
                <div className="data-value">{formData?.noOfPositions}</div>
              )}
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-gray-700 font-bold mb-2"
            >
              Description
            </label>

            {!isExisted || isMainDetailsEdit ? (
              <textarea
                type="text"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Required Description"
                required
              />
            ) : (
              <div className="data-value">{formData?.description}</div>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="qualifications"
              className="block text-gray-700 font-bold mb-2"
            >
              Requirments
            </label>

            {!isExisted || isMainDetailsEdit ? (
              <div>
                  <button style={{ float: "right" }} onClick={handleAddRequirments}>
                + Add Requirments
              </button>
              {formData?.qualifications.map(
                (qualification, index) => (
                  <input
                    key={index}
                    type="text"
                    name="qualifications"
                    value={qualification}
                    className="shadow appearance-none border rounded w-full mb-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    onChange={(e) => handleListChange(e, index)}
                  />
                )
              )}
            
              </div>
            ) : (
              <div className="data-value">
                <ul>
                  {formData?.qualifications.map((qualification, index) => (
                    <li key={index}>
                      <label>{qualification}</label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
              <div className="mb-4">
          <label
            htmlFor="rolesAndResponsibilities"
            className="block text-gray-700 font-bold mb-2"
          >
            Roles & Responsibilities
          </label>

          {!isExisted || isMainDetailsEdit ? (
            <div>
              <button style={{ float: "right" }} onClick={handleAddRole}>
                + Add Role
              </button>
              {formData?.rolesAndResponsibilities.map(
                (rolesAndResponsibility, index) => (
                  <input
                    key={index}
                    type="text"
                    name="rolesAndResponsibilities"
                    value={rolesAndResponsibility}
                    className="shadow appearance-none border rounded w-full mb-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    onChange={(e) => handleListChange(e, index)}
                  />
                )
              )}
            </div>
          ) : (
            <div className="data-value">
              <ul>
                {formData?.rolesAndResponsibilities.map(
                  (rolesAndResponsibility, index) => (
                    <li key={index}>
                      <label>{rolesAndResponsibility}</label>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
        </div>
        <div className="mb-4">
            <label
              htmlFor="skills"
              className="block text-gray-700 font-bold mb-2"
            >
              Skills
            </label>
            {!isExisted || isMainDetailsEdit ? (
              <SkillsTagInput
                value={formData.skills}
                onChange={handleSkillsChange}
              />
            ) : (
              <div className="data-value">{formData?.skills.join(", ")}</div>
            )}
          </div>
          
          </div>
      

      

        <div className="grid grid-cols-2 gap-4">
        <div className="mb-4">
              <label
                htmlFor="experience"
                className="block text-gray-700 font-bold mb-2"
              >
                Experience
              </label>

              {!isExisted || isMainDetailsEdit ? (
                <input
                  type="text"
                  id="experience"
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter Required Experience"
                  required
                />
              ) : (
                <div className="data-value">{formData?.experience}</div>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="location"
                className="block text-gray-700 font-bold mb-2"
              >
                Location
              </label>

              {!isExisted || isMainDetailsEdit ? (
                <select
                  id="locationDropdown"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="" disabled>
                    Select Location
                  </option>
                  <option value="Location 1">Location 1</option>
                  <option value="Location 2">Location 2</option>
                  {/* Add more options as needed */}
                </select>
              ) : (
                <div className="data-value">{formData?.location}</div>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="salary"
                className="block text-gray-700 font-bold mb-2"
              >
                Salary Range In LPA
              </label>

              {!isExisted || isMainDetailsEdit ? (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <input
                      type="number"
                      id="minSalary"
                      name="minSalary"
                      value={formData.minSalary}
                      onChange={handleChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Minimum Salary"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      id="maxSalary"
                      name="maxSalary"
                      value={formData.maxSalary}
                      onChange={handleChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Maximum Salary"
                      required
                    />
                  </div>
                </div>
              ) : (
                <div className="data-value">
                  {formData?.minSalary} to {formData?.maxSalary}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="applicationDeadLine"
                className="block text-gray-700 font-bold mb-2"
              >
                Application DeadLine
              </label>

              {!isExisted || isMainDetailsEdit ? (
                <input
                  type="date"
                  id="applicationDeadLine"
                  name="applicationDeadLine"
                  value={formData.applicationDeadline}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Select Deadline"
                />
              ) : (
                <div className="data-value">
                  {formData?.applicationDeadline}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="hiringManager"
                className="block text-gray-700 font-bold mb-2"
              >
                Hiring Manager
              </label>

              {!isExisted || isMainDetailsEdit ? (
                <select
                  id="hiringManager"
                  name="hiringManager"
                  value={formData.hiringManager}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                >
                  <option value="">Select Hiring Manager</option>
                  <option value="Manager 1">Manager 1</option>
                  <option value="Manager 2">Manager 2</option>
                  {/* Add more options as needed */}
                </select>
              ) : (
                <div className="data-value">{formData?.hiringManager}</div>
              )}
            </div>
          <div className="mb-4">
            <label
              htmlFor="contactEmail"
              className="block text-gray-700 font-bold mb-2"
            >
              Contact Email Id:
            </label>

            {!isExisted || isMainDetailsEdit ? (
              <input
                type="text"
                id="contactEmail"
                name="contactEmail"
                value={formData.contactEmail}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Contact Email"
                required
              />
            ) : (
              <div className="data-value">{formData?.contactEmail}</div>
            )}
          </div>

        

          <div className="mb-4">
            <label
              htmlFor="benefits"
              className="block text-gray-700 font-bold mb-2"
            >
              Benefits
            </label>

            {!isExisted || isMainDetailsEdit ? (
              <input
                type="text"
                id="benefits"
                name="benefits"
                value={formData.benefits}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter Required Benefits"
                required
              />
            ) : (
              <div className="data-value">{formData?.benefits}</div>
            )}
          </div>

          <div className="flex justify-center">
            <button
              onClick={handleSubmit}
              type="submit"
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                isExisted ? "hidden" : ""
              }`}
            >
              Submit
            </button>
          </div>
        </div>
        </div>}
      </div>
    </div>
  );
}

export default JobForm;
