import React, { useState, useEffect } from "react";
import candidateService from "../../service/candidateService";
import { useAuth0 } from "@auth0/auth0-react";
import superagent from "../../service/weevaluateagent";
import Table, { AvatarCell } from "../../../../components/OTable/Table";
import UploadResumeWithDropzone from "./UploadResumeWithDropzone";
import Loading from "../../../../components/Loading";
import OTabs from "../../../../components/OTabs/OTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";


const CandidatesList = ({onSelectCandidate}) => {
  const { getAccessTokenSilently } = useAuth0();
  // State to store the list of candidates
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isDataLoaded,setIsDataLoaded]=useState(false)
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  // Fetch candidates when the component mounts
  useEffect(() => {
    fetchCandidates();
  }, [getAccessTokenSilently]);
  useEffect(() => {
    if (showModal) {
      fetchCandidates();
      closeModal()
    }
  }, [selectedCandidate]);

  // Function to fetch all candidates
  const fetchCandidates = async () => {
    try {
      const token = await getAccessTokenSilently();
      superagent.setToken(token);
      const candidatesData = await candidateService.getAllCandidates(token);
      setCandidates(candidatesData);
      setIsDataLoaded(true)
    } catch (error) {
      setCandidates([]);
      console.error("Error fetching candidates:", error);
    }
  };
  const handleCandidateSelection = (candidate) => {
    setSelectedCandidate(candidate);
    onSelectCandidate(candidate)
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "role",
        width:"10%",
        Cell: ({ row }) => (
          <>{
          <input type="radio" name="candidate" onClick={() =>  handleCandidateSelection(row.original)}/>
          } 
          </>
        ),
      },
      {
        Header: "Candidate",
        accessor: "fullName",
        Cell: AvatarCell,
        nameAccessor: "fullName",
        emailAccessor: "email",
        width:"60%",
      },{
        Header: "Skills",
        accessor: "time",
        width:"20%",
        Cell: ({ row }) => (
          <div style={{
            width: '150px',
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
            {displaySkills(row.original.skills)}
          </div>
        ),
      },
      
    ],
    []
  );
  const tabs = [
    {
      name: 'tab2',
      label: 'Upload Job',
      content: (
        <UploadResumeWithDropzone setSelectedCandidate={handleCandidateSelection}/>  
      ),
    },
  ];
  const displaySkills = (skills) => {
    let displayedSkills = '';
    for (let i = 0; i < Math.min(5, skills.length); i++) {
      displayedSkills += skills[i].skill;
      if (i < Math.min(4, skills.length - 1)) {
        displayedSkills += ', ';
      }
    }
    return displayedSkills;
  };
  return (
    <div>
        {/* Modal */}
        {showModal && (
        <div className="fixed z-30 top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="modal-overlay fixed w-full h-full bg-gray-800 opacity-80"></div>
          <div className="modal z-50 bg-white rounded-lg overflow-hidden shadow-lg ">
            {/* Modal header */}
            <div className="modal-header px-4 py-2 bg-gray-200 flex justify-between items-center">
              <h2 className="text-lg font-semibold">Add Candidate</h2>
              <button onClick={closeModal} className="text-gray-600 hover:text-gray-900 focus:outline-none">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body px-4 py-2 w-full">
            <OTabs tabs={tabs} />
            </div>
          </div>
        </div>
      )}
      {!isDataLoaded && <Loading/>}
     
      {candidates && isDataLoaded &&  <Table  style={{height:'300px'}} columns={columns} data={candidates} html={<><button onClick={fetchCandidates} className="bg-blue-500 z-1 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"><FontAwesomeIcon icon={faRefresh}/></button>   <button onClick={openModal} className="bg-blue-500 z-1 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Add Candidate
      </button></>} />}
        
      </div>
   
  );
};

export default   React.memo(CandidatesList);
