import React, { useEffect, useState } from 'react';
import { useParams,useLocation } from 'react-router-dom';
import superagent from "../../services/weevaluateagent";
import jobService from '../../services/jobService';
import { useAuth0 } from '@auth0/auth0-react';
import ApplicantsList from './ApplicantsList';


const Applicants = () => {
  const { jobId } = useParams();
  const location = useLocation();
  const job = location.state?.job; 
//  const { id } = useParams(); // Job ID from the URL
  const [applicants, setApplicants] = useState([]);
  const { getAccessTokenSilently,user } = useAuth0();

  console.log("job Object",job)

  useEffect(() => {
    // Fetch the list of candidates for the given job ID

    const fetchApplicants = async (job) => {
      
      try {

        const token = await getAccessTokenSilently();
        superagent.setToken(token);
        const response = await jobService.getApplicantsByJob(job);
        console.log(response); 
        setApplicants(response);
      } catch (error) {
        console.error('Error fetching applicants:', error);
      }
    };
    if(job!==null) {
      fetchApplicants(job);
    }
  }, [job]);

   return (
        <div className="container mx-auto">
            <h1 className="text-2xl font-bold text-center my-4">Applicants</h1>
           {applicants && <ApplicantsList applicants={applicants} />}
        </div>
    )

  };
export default Applicants;
