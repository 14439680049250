import React from 'react';

const ApplicantCard = ({ applicant }) => {
    return (
        <div className="bg-white shadow-md rounded-lg p-4 m-2 w-80 flex flex-col justify-between">
            <div>
                <h2 className="text-xl font-semibold mb-2">{applicant.fullName}</h2>
                <p className="text-gray-600 mb-1">Email: {applicant.email}</p>
                <p className="text-gray-600 mb-1">Phone: {applicant.phone}</p>
                <p className="text-gray-600 mb-1">LinkedIn: {applicant.linkedin}</p>
                <p className="text-gray-600 mb-1">GitHub: {applicant.github}</p>
            </div>
            <div className="flex flex-wrap justify-between mt-4">
                <button className="bg-blue-500 text-white px-2 py-1 rounded m-1 flex-1">View</button>
                <button className="bg-green-500 text-white px-2 py-1 rounded m-1 flex-1">Accept</button>
                <button className="bg-red-500 text-white px-2 py-1 rounded m-1 flex-1">Reject</button>
                <button className="bg-yellow-500 text-white px-2 py-1 rounded m-1 flex-1">Hold</button>
                <button className="bg-purple-500 text-white px-2 py-1 rounded m-1 flex-1">Analyse</button>
            </div>
        </div>
    );
};

export default ApplicantCard;
