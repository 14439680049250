import React, { useState } from 'react';

function SkillsTagInput({ value, onChange }) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      onChange([...value, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveTag = (indexToRemove) => {
    const updatedSkills = value.filter((_, index) => index !== indexToRemove);
    onChange(updatedSkills);
  };

  return (
    <div className="flex flex-wrap items-center">
      {value.map((tag, index) => (
        <div key={index} className="tag cursor-pointer bg-blue-300 rounded-full px-3 py-1 m-1 flex items-center">
          <span>{tag}</span>
          <span className="ml-1 text-red-500" onClick={() => handleRemoveTag(index)}>x</span>
        </div>
      ))}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="outline-none border-none py-1 px-2 mt-2 bg-gray-100 rounded"
        placeholder="Enter skills (Press Enter to add)"
      />
    </div>
  );
}

export default SkillsTagInput;
